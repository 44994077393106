<template>

  <div class="d-center-content fill-height" :class="{'mr-7': $vuetify.breakpoint.smAndDown}">

    <div class="d-skew-mother">
      <div class="d-skew-left px-10">{{catname}}</div>
      <div class="d-skew-center"></div>
      <div class="d-skew-right"></div>
      <div class="d-skew-abdecker "></div>
      <div class="d-skew-abdecker-white "></div>
    </div>
    <div class="d-content-content">
          <div v-if="Jcarts.length > 0">
            <v-card class="d-cards-reiseuebersicht mb-5" elevation="6" v-for="(item,a) in Jcarts" :key="a" style="cursor:pointer;" @click="navigateJourney(item)" :class="{'d-none': $vuetify.breakpoint.smAndDown}">
                <v-img v-if="item.img.link" class="" :src="item.img.link"  height="180" width="250"></v-img>
                <v-img v-else  height="180" width="250" :src="notPic"></v-img>
                <v-card-text style="position:absolute;top:0px;right:0px;width:calc(100% - 250px);height:inherit;" class="px-5 py-2">
                  <div class="subtitle-2 font-weight-bold" style="width:calc(100% - 40px);">{{item.title}}</div>
                  <div class="caption">{{item.img.subtitle}}</div>
                  <div class="caption pt-2 pr-5 D-einleitung" :class="{'d-none': $vuetify.breakpoint.mdAndDown}"> {{ setSub(item.einleitung) }}</div>
                  <div class="mb-5" style="position:absolute;width:calc(100% - 70px); bottom:-10px;">
                    <div v-if="item.duration==1" style="float:left;" class="pt-2 font-weight-bold">{{settheDate(item.date_from)}}</div>
                    <div v-else="item.duration==1" style="float:left;" class="pt-2 font-weight-bold">{{settheDate(item.date_from)}} - {{settheDate(item.date_till)}}</div>
                    <div class="primary--text pl-1 title font-weight-bold" style="float:right"><span class="caption">ab</span> {{ new Intl.NumberFormat('de-DE').format(item.price) }} ,- €</div>
                    <!-- <div class="primary--text pl-1 title font-weight-bold" style="float:right"><span class="caption">ab</span> {{ item.price }} ,- €</div> -->
                  </div>
                  <div class="primary" style="position:absolute;height:100%;width:30px; right:0px;top:0px;">
                    <fai class="usbg--text ml-1" :icon="['fas', 'arrow-circle-right']" style="position:absolute;font-size:22px;top:calc(50% - 11px);" ></fai>
                  </div>
                </v-card-text>
              </v-card>
              <v-card class="mr-5 mb-5 reiseuebersicht-small"  v-for="(item) in Jcarts" :key="item.name" style="cursor:pointer;" @click="navigateJourney(item)" :class="{'d-inline-block': $vuetify.breakpoint.smAndDown}">
                <v-img v-if="item.img.link" class="" height="200px" :src="item.img.link" >
                  <v-card-title class="justify-end pa-0">
                    <div style="background:rgba(255,255,255,0.6)" class="px-2 float-right">
                      <span class="body-2 pt-1">ab</span>
                      <span class="primary--text pl-1 title font-weight-bold"> {{ new Intl.NumberFormat('de-DE').format(item.price) }} ,- €</span>
                    </div>
                  </v-card-title>
                </v-img>
                <v-img v-else class="" height="200px" :src="notPic" >
                  <v-card-title class="justify-end pa-0">
                    <div style="background:rgba(255,255,255,0.6)" class="px-2 float-right">
                      <span class="body-2 pt-1">ab</span>
                      <span class="primary--text pl-1 title font-weight-bold"> {{ new Intl.NumberFormat('de-DE').format(item.price) }} ,- €</span>
                    </div>
                  </v-card-title>
                </v-img>
                <v-card-text class="" style="height:90px;overflow:hidden" :class="{'d-cards-down': $vuetify.breakpoint.sm}">
                  <div class="font-weight-bold">{{item.title}}</div>
                  <div class="body-2">{{settheDate(item.date_from)}} - {{settheDate(item.date_till)}}</div>
                </v-card-text>
            </v-card>
          </div>
          <div v-else>
              <div v-if="tempSpinner" style="display:flex;align-items: center; justify-content: center;padding-top:30px;">
                  <v-progress-circular :size="200" :width="20" color="primary" indeterminate ></v-progress-circular>
              </div>
              <div v-else>
                <v-card class="d-cards-reiseuebersicht mb-5" elevation="6" :class="{'d-none': $vuetify.breakpoint.smAndDown}">
                  <v-img  height="180" width="250" :src="notPic"></v-img>
                  <v-card-text style="position:absolute;top:0px;right:0px;width:calc(100% - 250px);height:inherit;" class="px-10 py-10">
                    <div class="title font-weight-bold primary--text py-8" style="width:calc(100% - 40px);">Keine Reise gefunden</div>
                  </v-card-text>
                </v-card>
                <v-card class="mr-5 mb-5 reiseuebersicht-small" :class="{'d-inline-block': $vuetify.breakpoint.smAndDown}">
                  <v-img class="" height="200px" :src="notPic" > </v-img>
                  <v-card-text class="" style="height:90px;overflow:hidden" :class="{'d-cards-down': $vuetify.breakpoint.sm}">
                    <div class="subtitle-1 font-weight-bold primary--text">Keine Reise gefunden</div>
                  </v-card-text>
                </v-card>
              </div>
          </div>
    </div>
  </div>
</template>


<script>

  import notPic from "@/assets/images/notPic.jpg"
  import { mapState } from "vuex"
  import moment from 'moment'


  export default {
    computed: {
        ...mapState(["JourneyCategoryStore", "SearchStore"]),
        // ...mapState(["JourneyStore", "JourneyCategoryStore", "SearchStore"]),
    },
    data() {
        return {
          tempSpinner:true,
          Jcarts: [],
          args:null,
          categoryId:null,
          catname:null,
          notPic,
          tempJourney:[]
        }
    },
    watch: {
        $route(to, from) {
          this.args = [];
          this.tempSpinner = true;
          this.args.push(to.params.reiseuebersicht);
          this.setJourneys();

          // console.log('chris '+ this.args);

        }
    },
    mounted() {
      this.args = core.getPathArgs(["id"].id);
      this.setJourneys();


      // console.log(this.args);

    },
    methods: {
      setSub(str) {

        var length = 456;
        var newStr = str.substring(0, length);
        if(str.length > length) {newStr = newStr + ' ...'}


        return newStr

      },
      navigateJourney(jrn) {
              core.navigate({ path: "/reise/" + jrn.id_jrn_journey + "/" + jrn.title.replace(/ /g,'-').replace(/[^\w-]+/g,'') })
      },
      settheDate(date){ return  moment(String(date)).format('DD.MM.YYYY'); },
      setJourneys() {
          this.Jcarts = [];

          this.args = core.getPathArgs(["category", "search"])

          // console.log(this.args);


          try {
              this.journeyCategory = this.JourneyCategoryStore.find(cat => cat.name == decodeURIComponent(this.args.category)).id_jrn_category
              this.catname = this.JourneyCategoryStore.find(cat => cat.name == decodeURIComponent(this.args.category)).name
          } catch (error) {
              this.journeyCategory = ""
              this.catname = decodeURIComponent(this.args.category)

          }

          let args = {
              id_acc_account: core.id_acc_account,
              category: this.journeyCategory,
              showEinleitung: true,
              showReisebeschreibung:true,
          }


          if(this.args.category === 'Tagesfahrten'){
          // if(args.category === 55){
              args.category = null;
              args.duration = [0,1]
          }

          if(this.args.category === 'Mehrtagesreisen'){
              args.category = null;
              args.duration = [2,99]
          }

          if (this.args.category == "search") {
              this.catname = "Reisesuche";

              // console.log(this.SearchStore.search);

              this.$store.commit("updateSearchStore", JSON.parse(this.args.search))




              args.country = typeof this.SearchStore.country == 'object' ? this.SearchStore.country : [this.SearchStore.country]
              args.category = this.SearchStore.category
              args.search = this.SearchStore.search
              args.date = {start:this.SearchStore.date_from,end:this.SearchStore.date_till}


          }

            // console.log(args);



          core.backend("jrn.listJourneysWeb", args, (err, res) => {
              if (err) {
                  return
              }

              this.tempJourney = [];
              res.data.rows.forEach(jrney => {
                  jrney.dates.forEach(date => {
                      let _newJourney = JSON.parse(JSON.stringify(jrney))
                      _newJourney.dates = [date]
                      if (date.price === 0) { return }


                      this.Jcarts.push({
                          title: _newJourney.title,
                          date_from: date.date_from,
                          date_till: date.date_till,
                          einleitung: _newJourney.einleitung,
                          reisebeschreibung: _newJourney.reisebeschreibung,
                          duration: date.duration,
                          price: (date.price/1000000),
                          id_jrn_journey: _newJourney.id_jrn_journey,
                          id_prj_project: _newJourney.id_prj_project,
                          img: _newJourney
                      });
                      this.tempJourney.push({
                          id:_newJourney.id_jrn_journey,
                          title: _newJourney.title,
                          path: "/reise/" + _newJourney.id_jrn_journey + "/" + _newJourney.title.replace(/ /g,'-').replace(/[^\w-]+/g,'')
                      })
                      this.$store.commit('setJourneyPanz', this.tempJourney);



                  })
              })
              this.Jcarts = this.Jcarts.sort((a, b) => new Date(a.date_from) - new Date(b.date_from))
              this.tempSpinner = false;
              // this.Jcarts = this.Jcarts.sort((a, b) => a.title > b.title)
          })


      }
    },

  };
</script>

<style lang="scss">
  @media (max-width: 599px) {
      .reiseuebersicht-small { width:270px; }

  }
  @media (min-width: 600px) and (max-width: 959px) {
      .reiseuebersicht-small { width:240px; }

  }
  @media (min-width: 960px) and (max-width: 1263px) {


  }
  @media (min-width: 1264px) and (max-width: 1903px) {


  }
  @media (min-width: 1904px) {


  }
  .d-cards-reiseuebersicht{
    width:100%;
    height:180px;
    position:relative;
  }
   .reiseuebersicht-small {
      position:relative;
      display:none;
    }


    .D-einleitung{
      line-height:120% !important;
    }




</style>
